<template>
  <div v-if="getLoaded()" class="duck-form form-width-medium form-center">
    <NotiBar v-if="msg.show" :type="msg.type" :msg="msg.text" />
    
    <form @submit.prevent="saveForm">
      <fieldset class="form-block form-banner">
        <div class="h-title">
          <h3>Custom Banner</h3>
        </div>

        <div class="form-container">
          <div class="input" data-slug="banner-1">
            <SmallTitle title="Banner 1" />
            <div class="row2">
              <div class="col">
                <SingleImageUploader type="banner-1"
                                      section="content" 
                                      :parentId="contentData.ID"
                                      description="รูปขนาด 1,400x900 pixel" />
              </div>
              <div class="col">
                <div class="input">
                  <input type="text" v-model="contentData.meta.banner.b1.title" placeholder="title...">
                  <input type="text" v-model="contentData.meta.banner.b1.url" placeholder="url..." class="mt-5">
                </div>
              </div>
            </div>
          </div>
          <!-- end: banner 1 -->
          
          <div class="input" data-slug="banner-2">
            <SmallTitle title="Banner 2" />
            <div class="row2">
              <div class="col">
                <SingleImageUploader type="banner-2" 
                                      section="content" 
                                      :parentId="contentData.ID"
                                      description="รูปขนาด 1,400x900 pixel" />
              </div>
              <div class="col">
                <div class="input">
                  <input type="text" v-model="contentData.meta.banner.b2.title" placeholder="title...">
                  <input type="text" v-model="contentData.meta.banner.b2.url" placeholder="url..." class="mt-5">
                </div>
              </div>
            </div>
          </div>
          <!-- end: banner 2 -->
          
          <div class="input" data-slug="banner-3">
            <SmallTitle title="Banner 3" />
            <div class="row2">
              <div class="col">
                <SingleImageUploader type="banner-3" 
                                      section="content" 
                                      :parentId="contentData.ID"
                                      description="รูปขนาด 1,400x900 pixel" />
              </div>
              <div class="col">
                <div class="input">
                  <input type="text" v-model="contentData.meta.banner.b3.title" placeholder="title...">
                  <input type="text" v-model="contentData.meta.banner.b3.url" placeholder="url..." class="mt-5">
                </div>
              </div>
            </div>
          </div>
          <!-- end: banner 3 -->
          
          <div class="input" data-slug="banner-4">
            <SmallTitle title="Banner 4" />
            <div class="row2">
              <div class="col">
                <SingleImageUploader type="banner-4" 
                                      section="content" 
                                      :parentId="contentData.ID"
                                      description="รูปขนาด 1,400x900 pixel" />
              </div>
              <div class="col">
                <div class="input">
                  <input type="text" v-model="contentData.meta.banner.b4.title" placeholder="title...">
                  <input type="text" v-model="contentData.meta.banner.b4.url" placeholder="url..." class="mt-5">
                </div>
              </div>
            </div>
          </div>
          <!-- end: banner 4 -->
          
          <div class="input" data-slug="banner-5">
            <SmallTitle title="Banner 5" />
            <div class="row2">
              <div class="col">
                <SingleImageUploader type="banner-5" 
                                      section="content" 
                                      :parentId="contentData.ID"
                                      description="รูปขนาด 1,400x900 pixel" />
              </div>
              <div class="col">
                <div class="input">
                  <input type="text" v-model="contentData.meta.banner.b5.title" placeholder="title...">
                  <input type="text" v-model="contentData.meta.banner.b5.url" placeholder="url..." class="mt-5">
                </div>
              </div>
            </div>
          </div>
          <!-- end: banner 5 -->
          
          <div class="input" data-slug="banner-6">
            <SmallTitle title="Banner 6" />
            <div class="row2">
              <div class="col">
                <SingleImageUploader type="banner-6" 
                                      section="content" 
                                      :parentId="contentData.ID"
                                      description="รูปขนาด 1,400x900 pixel" />
              </div>
              <div class="col">
                <div class="input">
                  <input type="text" v-model="contentData.meta.banner.b6.title" placeholder="title...">
                  <input type="text" v-model="contentData.meta.banner.b6.url" placeholder="url..." class="mt-5">
                </div>
              </div>
            </div>
          </div>
          <!-- end: banner 6 -->

        </div>
      </fieldset>

      <div class="button-block">
        <button type="submit" class="btn btn-update">
          <i class="fa fa-save fa-lg"></i> บันทึกข้อมูล
        </button>
      </div>
    </form>
  </div>
</template>


<script>
// @ is an alias to /src
import getPostItem        from '@/utils/post/getPostItem.js';
import savePostItem       from '@/utils/post/savePostItem.js';
import preparePostItem    from '@/utils/post/preparePostItem.js';

import NotiBar            from '@/components/common/NotiBar.vue';
import SmallTitle         from '@/components/common/SmallTitle.vue';
import SingleImageUploader from '@/components/file/SingleImageUploader.vue';

export default {
  data() {
    return {
      loaded: false,
      msg: {
        show: false,
        type: 'success',
        text: '',
      },

      contentId: '',
      contentData: {
        publish: {},
      },
      isEditForm: false,
    }
  },
  components: {
    NotiBar,
    SmallTitle,
    SingleImageUploader,
  },
  created() {
    this.contentId = this.$route.params.id;
    this.loadPage();
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    
    loadPage:function() {
      this.$store.commit('setLoaderShow', true);

      getPostItem(this.contentId)
        .then((res) => {
          this.contentData = preparePostItem(res);
          this.isEditForm = this.contentData.formType == 'edit';

          this.setPageTitle();
          this.$store.commit('setLoaderShow', false);
        });
    },
    saveForm:function() {
      this.$store.commit('setLoaderShow', true);
      // console.log(this.contentData)

      savePostItem(this.contentData)
        .then( (res) => {
          this.msg.show = true;
          
          if(res.data.status==200) {
            this.msg.text = 'Save data complete';
            this.isEditForm = true; // force change this page to Edit Form

            this.setPageTitle();
          }else{
            this.msg.type = 'error';
            this.msg.text = 'Save data fail. Please, try again.';
          }

          this.$store.commit('setLoaderShow', false);
        });
    },

    /**
     * Set page title to 'Add' or 'Edit'
     */
    setPageTitle() {
      if(this.pageTitle!=='') {
        let title = ( this.isEditForm ? 'Edit ':'Add ' ) + 'Recommended';
        this.$store.commit('setPageTitle', title);
        this.$store.commit('setHeadTitle', title);
      }
    },
  }
}
</script>

<style scoped>
/*

.preview-link {
  margin-top: 5px;
}
.select-time {
  padding-left: 5px;
  width: 45px;
} */

.mt-5 {margin-top: 5px;}
.form-banner .input {padding-bottom: 15px;}
.form-banner .input+.input { 
  padding-top: 25px;
  border-top: solid 2px #000;
}
</style>